import style from './index.module.css'
import moment from 'moment-timezone'
import {useEffect, useState} from 'react'

var dateInterval

export default function Calendar() {
   const [currentMoment, setCurrentMoment] = useState(moment())
   
   useEffect(() => {
      if (dateInterval) clearInterval(dateInterval)

      dateInterval = setInterval(() => {
         setCurrentMoment(moment())
      }, 1000)

      return () => {
         if(dateInterval) clearInterval(dateInterval)
      }

   }, [])

   return (
      <div className={style.main}>
         <div className={style.time}>{currentMoment.format('HH:mm')}</div>
         <div className={style.date}>{currentMoment.format('DD/MM/YYYY')}</div>
      </div>
   )
}