import style from './index.module.css'
import WindowIcon from '@mui/icons-material/Window';
import {useEffect, useState} from 'react'
import Start from './Start'
import Window from '../Window'
import About from '../About'
import Calendar from './Calendar'
import Wifi from './Wifi'
import NotFoundApps from './NotFoundApps'
import NotFoundContent from './NotFoundContent'

import aboutIcon from 'static/app-icons/about.png' // FOr Testing
import errorIcon from 'static/app-icons/error.png'

export default function Taskbar({menuApplications, onCloseMenuApplication}) {
    const [startShow, setStartShow] = useState(false)
    const startToggle = () => setStartShow(x => !x);

    const [windows, setWindows] = useState([])

    useEffect(() => {
        var x = [...windows]
        x.push({
            title: "About",
            width: 500,
            height: 400,
            icon: aboutIcon,
            content: <About />,
            id: 'about'
        })
        setWindows(x)
    }, [])

    const handleWindowClose = winId => {
        var x = [...windows]
        const i = x.findIndex(x => x.id === winId)
        if( i >= 0) {
            x.splice(i, 1)
        }
        setWindows(x)
    }

    const windowNotFoundToggle = (id, title) => {
        var wins = [...windows]
        const i = wins.findIndex(x => x.id === id)
        if(i<0) {
            wins.push({
                title: `${title}.exe`,
                width: 400,
                height: 150,
                icon: errorIcon,
                content: <NotFoundContent id={id} onClose={(id) => handleWindowClose(id)} />,
                id
            })
        } else {
            wins.splice(i, 1)
        }

        setWindows(wins)
    }

    useEffect(() => {
        setTimeout(() => {
            setStartShow(true)
        }, 1000)
    }, [])

    return (
        <>
            <Start open={startShow} onClose={startToggle} />
            {windows.map((win, index) => (
                <Window
                    id={win.id}
                    key={index}
                    title={win.title}
                    defaultWidth={win.width}
                    defaultHeight={win.height}
                    content={win.content}
                    icon={win.icon}
                    onClose={handleWindowClose}
                />
            ))}
            {menuApplications.map((app, index) => (
                <Window 
                    id={app.id}
                    key={index}
                    title={app.title}
                    defaultWidth={app.defaultWidth}
                    defaultHeight={app.defaultHeight}
                    content={app.content}
                    icon={app.icon}
                    onClose={onCloseMenuApplication}
                    noResize={app.noResize}
                />
            ))}
            <div className={style.taskbar}>
                <div className={style.apps}>
                    <button className={style.app}  onClick={startToggle} title={'Start'} active={startShow ? '1' : '0'}>
                        <WindowIcon className={style.app_icon} />
                    </button>
                    {NotFoundApps.get().map((x, i) => (
                        <button key={i} className={style.app} title={x.title} onClick={() => windowNotFoundToggle(x.id, x.title)}>
                            <img src={x.icon} className={style.app_icon} width='24' alt={x.title} title={x.title} />
                        </button>    
                    ))}
                    {menuApplications.map((x, i) => (
                        <button key={i} className={style.app} title={x.title} active={1}>
                            <img src={x.icon} className={style.app_icon} width='24' alt={x.title} title={x.title} />
                        </button>  
                    ))}
                </div>
                <div className={style.right}>
                    <Wifi />
                    <Calendar />
                </div>
            </div>
        </>
    )
}