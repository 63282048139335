import './index.scss'
import icon from 'static/icon/wifi.png'
import {useState, useEffect} from 'react'
import WifiDialog from './WifiDialog'

export default function Wifi() {

   const [open, setOpen] = useState(false)
   const openToggle = () => setOpen(x => !x)

   useEffect(() => {
      setTimeout(() => {
         setOpen(true)
      }, 1800)
   }, [])

   return (
      <>
         <WifiDialog open={open} onClose={() => setOpen(false)} />
         <div className="wifi" onClick={openToggle}>
            <img src={icon} className='icon' alt='Wifi Icon' />
         </div>
      </>
   )
}