import style from '../index.module.css'
import fiko942 from 'static/images/fiko942.jpg'
import Socials from './Socials'



const media = {
    emails: ['tobellord@gmail.com']
}

export default function Profile() {
    return (
        <div className={style.profile}>
            <img src={fiko942} className={style.avatar} />
            <h5 className={style.name}>Wiji Fiko Teren</h5>
            <div className={style.emails}>
            {media.emails.map((email, index) => (
                <>
                    <a href={`mailto:${email}`} className={style.email} key={index}>{email}</a>{index !== (media.emails.length - 1) && <div className={style.separator} />} 
                </>
            ))}
            </div>
            <Socials />
        </div>
    )
}