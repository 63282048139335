import './idcard.scss'
import photo from 'static/images/fiko942.jpg'

export default function IDCard() {
   return (
      <div className="idcard">
         <div className="left">
            <img src={photo} alt="Wiji Fiko Teren" className="img" title='Wiji Fiko Teren' />
         </div>
         <div className="right-details">
            <table>
               <th>
                  <tr>
                     <td>Name</td>
                     <td className='bold'>Wiji Fiko Teren</td>
                  </tr>
                  <tr>
                     <td>Born</td>
                     <td className='bold'>26 Sep 2004</td>
                  </tr>
                  <tr>
                     <td>Age</td>
                     <td className='bold'>18 Years</td>
                  </tr>
                  <tr>
                     <td>Live</td>
                     <td className='bold'>Malang, East Java, Indonesia.</td>
                  </tr>
                  <tr>
                     <td>Gender</td>
                     <td className="bold">Male ♂</td>
                  </tr>
               </th>
            </table>
         </div>
      </div>
   )
}