import './wifi-dialog.scss'
import {useEffect} from 'react'
import securedWifiIcon from 'static/icon/wifi-secured.png'

export default function WifiDialog({open, onClose}) {

   useEffect(() => {
      const wifiDialog = document.querySelector('.wifi-dialog')
      open && !wifiDialog.classList.contains('open')  && wifiDialog.classList.add('open')
      !open && wifiDialog.classList.contains('open') && wifiDialog.classList.remove('open')
   }, [open])

   return (
      <>
      <div className="wifi-dialog-wrapper" />
      <div className="wifi-dialog">
      <div className="networks">
         <div className="network">
            <div className="left">
               <img src={securedWifiIcon} alt="Secured Wifi Icon" className="icon" />
            </div>
            <div className="right">
               <div className="name">Wiji Fiko Teren's Home</div>
               <div className="state-information">Connected, secured.</div>
            </div>
            <button className="disconnect">Disconnect</button>
         </div>
      </div>
      </div>
      </>

   )
}