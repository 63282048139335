import './education.scss'
import logoTsanedo from 'static/images/mtsn5malang.png'
import logoMuhendo from 'static/images/muhendo.png'
import calendarIcon from 'static/icon/calendar.png'

export default function Education() {
   return (
      <div className="education">
         <div className="item">
            <div className="left">
               <div className="name">MTsN 5 Malang</div>
               <div className="desc">Information and Communication Technology</div>

               <div className="date">
                  <img src={calendarIcon} alt="Date Icon" className="icon" />
                  <div className="text">2016 - 2019</div>
               </div>
            </div>
            <div className="right">
               <img src={logoTsanedo} alt="Tsanedo" className="logo" title='Tsanedo' />
            </div>
         </div>
         <div className="item">
            <div className="left">
               <div className="name">SMKM 6 Donomulyo</div>
               <div className="desc">Network computer Engineering</div>

               <div className="date">
                  <img src={calendarIcon} alt="Date Icon" className="icon" />
                  <div className="text">2019 - 2022</div>
               </div>
            </div>
            <div className="right">
               <img src={logoMuhendo} alt="Muhendo" className="logo" title='Muhendo' />
            </div>
         </div>
      </div>
   )
}