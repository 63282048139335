import style from './socials.module.css'


import facebookIcon from './media-icons/facebook.png'
import githubIcon from './media-icons/github.png'
import instagramIcon from './media-icons/instagram.png'
import linkedinIcon from './media-icons/linkedin.png'
import whatsappIcon from './media-icons/whatsapp.png'


export default function Socials() {
   const media = [
      {
         name: 'Facebook',
         url: 'https://facebook.com/fiko.tobel',
         icon: facebookIcon
      },
      {
         name: 'Github',
         url: 'https://github.com/fiko942',
         icon: githubIcon
      },
      {
         name: 'Instagram',
         url: 'https://instagram.com',
         icon: instagramIcon
      },
      {
         name: 'Linkedin',
         url: 'https://www.linkedin.com/in/wiji-fiko-teren-4a7a00219/',
         icon: linkedinIcon
      },
      {
         name: 'WhatsApp',
         url: 'https://wa.me/6285935099343',
         icon: whatsappIcon
      }
   ]
   

   return (
      <div className={style.main}>
         {media.map((social, index) => {
            return (
               <a href={social.url} target="_blank" className={style.social} key={index}>
                  <img alt={social.name} title={social.name} src={social.icon} />
               </a>
            )
         })}
      </div>
   )
}