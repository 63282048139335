import style from './index.module.css'

export default function About() {
   return (
      <div className={style.about}>
         <div className={style.welcome_bold}>
            Hi there ✋,
         </div>
         <div className={style.welcome_bold}>
         im <b>Wiji Fiko Teren</b> 🧑‍💻,
         </div>
         <p className={style.p}>
         I currently working at <b>Kampung Songo</b> as a <b>Programmer</b>, and i make <b>Tobelsoft</b> which focused on computer software. I'm tech fan. I am always open to collaborating on innovate/distruptive project and ideas. Find out more about me & feel free to connect with me.
         </p>
      </div>
   )
}