import './applications.scss'

import {useEffect, useState} from 'react'

// Icons
import idCardIcon from 'static/app-icons/idcard.png'
import emailIcon from 'static/app-icons/email.png'
import graduateIcon from 'static/app-icons/graduate.png'
import certificationIcon from 'static/app-icons/certification.png'
import jobIcon from 'static/app-icons/job.png'

import IDCard from './Content/IDCard'
import Education from './Content/Education'
import Certification from './Content/Certification'
import Experience from './Content/Experience'

const apps = [
   {
      title: 'ID Card',
      icon: idCardIcon,
      defaultWidth: 630,
      defaultHeight: 300,
      content: <IDCard />,
      noResize: true
   },
   {
      title: 'Education',
      icon: graduateIcon,
      defaultWidth: 500,
      defaultHeight: 320,
      content: <Education />,
      noResize: true
   },
   {
      title: 'Certification',
      icon: certificationIcon,
      defaultWidth: 1200,
      defaultHeight: 500,
      content: <Certification />,
      noResize: true
   },
   {
      title: 'Experience',
      icon: jobIcon,
      defaultWidth: 600,
      defaultHeight: 500,
      content: <Experience />,
      noResize: true
   }
]

export default function Applications({onOpen}) {

   const [currentSelected, setCurrentSelected] = useState(undefined)

   const handleOpen = (app) => {
      onOpen(app)
   }

   return (
      <div className="applications">
         <div className="single-vline">
            {apps.map((app, index) => (
               <div className="app" key={index} s={currentSelected === app.title ? '1' : '0'} onClick={() => setCurrentSelected(app.title)} onDoubleClick={() => handleOpen(app)}>
                  <div className="wrapper">
                     <img src={app.icon} alt={app.title} title={app.title} className="icon" />
                     <div className="name">{app.title}</div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}