import polsekDonomulyoIcon from 'static/images/experience/polda_jatim.png'
import tobelsoftIcon from 'static/images/experience/tobelsoft.png'
import kampungSongoIcon from 'static/images/experience/kampungsongo.png'

export default [
   {
      company: 'Police Station Donomulyo',
      date: {
         from: 'Jan 2021',
         to: 'Mei 2021'
      },
      type: 'Internship',
      position: 'Administration',
      companyLogo: polsekDonomulyoIcon
   },
   {
      company: 'Tobelsoft',
      date: {
         from: 'Mei 2020',
         to: 'Current'
      },
      type: 'Part Time',
      position: 'Owner and Programmer',
      companyLogo: tobelsoftIcon
   },
   {
      company: 'Kampung Songo',
      date: {
         from: 'July 2022',
         to: 'Current'
      },
      type: 'Full Time',
      position: 'Software Developer',
      companyLogo: kampungSongoIcon
   }
]