import logo from './logo.svg';
import Body from '@component/Body'
import ErrorWindowSize from './ErrorWindowSize'

function App() {
  return (
      <>
        <Body>
        </Body>
        <ErrorWindowSize />
      </>
  );
}

export default App;
