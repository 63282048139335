import {useEffect, useState, useRef} from 'react'
import style from './index.module.css'
import moveToCenter from './moveToCenter'
import closeIcon from './icons/close.png'
import closeWhite from './icons/close-white.png'
import resizeIcon from './icons/resize.png'


export default function Window({defaultWidth, defaultHeight, title, icon, content, onClose, noResize, ...props}) {
   const [width, setWidth] = useState(defaultWidth)
   const [height, setHeight] = useState(defaultHeight)
   const [hovered, setHovered] = useState(false)
   const main = useRef()

   useEffect(() => {
      moveToCenter(main.current)
   }, [])

   return (
      <div className={style.main} style={{
         height,
         width
      }}
      ref={main}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      data-window={props.id}
      {...props}
      >
         <Header base={main} title={title} icon={icon} onClose={() => onClose(props.id)} />
         <div className={style.wrapper}>
            {content}
           {hovered && !noResize && (
             <Resize base={main} minHeight={defaultHeight} minWidth={defaultWidth} />
           )}
         </div>
      </div>
   )
}

function Resize({base, minWidth, minHeight}) {
   const [drag, setDrag] = useState(false)

   const handleMove = e => {
      base.current.style.width = `${base.current.clientWidth+e.movementX}px`
      base.current.style.height = `${base.current.clientHeight+e.movementY}px`
   }

   useEffect(() => {
      if(drag) {
         window.addEventListener('mousemove', handleMove)
      } else {
         window.removeEventListener('mousemove', handleMove)
      }
      return () => {
         window.removeEventListener('mousemove', handleMove)
      }
   }, [drag])

   return (
      <div className={style.resize} style={{backgroundImage: `url('${resizeIcon}')`}}
         onMouseDown={() => setDrag(true)}
         onMouseUp={() => setDrag(false)}
      />
   )
}


function Header({base, title, icon, onClose}) {
   const [isDragging, setIsDragging] = useState(false)
   const [closeButtonHovered, setCloseButtonHovered] = useState(false)
   const handleMove = event => {
      const {pageX, pageY} = event
      base.current.style.top = `${pageY-15}px`;
      base.current.style.left = `${pageX-35}px`;
   }


   useEffect(() => {
      if(isDragging) {
         window.addEventListener('mousemove', handleMove)
      } else {
         window.removeEventListener('mousemove', handleMove)
      }
      return () => {
         window.removeEventListener('mousemove', handleMove)
      }
   }, [isDragging])

   return (
      <div className={style.header} 
         onMouseDown={() => setIsDragging(true)}
         onMouseUp={() => setIsDragging(false)}
      >
         <div className={style.left}>
            <img src={icon} className={style.icon} alt={title} title={title} />
            <div className={style.title}>{title}</div>
         </div>
         <div className={style.action}>
            <button onClick={onClose} className={style.close} onMouseOver={() => setCloseButtonHovered(true)} onMouseOut={() => setCloseButtonHovered(false)}>
               <img src={closeButtonHovered ? closeWhite : closeIcon} />
            </button>
         </div>
      </div>
   )
}