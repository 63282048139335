import explorerIcon from 'static/app-icons/explorer.png'
import edgeIcon from 'static/app-icons/edge.png'
import chromeIcon from 'static/app-icons/chrome.png'
import visualStudioIcon from 'static/app-icons/visual-studio.png'
import cmdIcon from 'static/app-icons/cmd.png'
import bashIcon from 'static/app-icons/bash.png'

function get() {
   return [
      {
         icon: explorerIcon,
         title: 'Explorer',
         id: 'explorer',
      },
      {
         icon: edgeIcon,
         title: 'Microsoft Edge',
         id: 'microsoft_edge'
      },
      {
         icon: chromeIcon,
         title: 'Google Chrome',
         id: 'google_chrome'
      },
      {
         icon: visualStudioIcon,
         title: 'Visual Studio',
         id: 'visual_studio'
      },
      {
         icon: cmdIcon,
         title: 'Cmd',
         id: 'cmd'
      },
      {
         icon: bashIcon,
         title: 'Bash',
         id: 'bash'
      }
   ]
}


export default {get}