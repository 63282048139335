import './certification.scss'
import data from './data'
import {useEffect, useState} from 'react'

export default function Certification() {
   const [hovered,setHovered] = useState(undefined)

   console.log('hovered: ', hovered)

   return (
      <div className="certification">
         {data.map((certificates, index) => ( 
            <div className='certificate-row' key={index}>
               {certificates.map((certificate, certificate_index) => (
                  <div className="certificate" key={certificate_index}
                     onMouseOver={() => setHovered(certificate.image)}
                     onMouseOut={() => setHovered(undefined)}
                     onClick={() => {
                        window.open(certificate.image, '_blank')
                     }}
                  >
                     {hovered === certificate.image && (
                        <a className='open_new' href={certificate.image} target='_blank' rel='noreferrer'>Open certificate in new window</a>
                     )}
                     <div className="wrapper">
                        <img src={certificate.image} alt={certificate.title} title={certificate.title} className='cert-image' />
                        <div className="name">{certificate.title}</div>
                        {certificate.desc && (
                           <p className="description">{certificate.desc}</p>
                        )}
                        <div className="issued">
                           <label htmlFor="">Issued</label>
                           <ul>
                              <li>At: <b>{certificate.issued.at}</b></li>
                              <li>By: <b>{certificate.issued.by.name}</b></li>
                              {certificate.issued.by.instance && <li>Instance: <b>{certificate.issued.by.instance}</b></li>}
                           </ul>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         ))}
      </div>
   )
}