export default function moveToCenter(target) {
   const windowWidth = window.innerWidth
   const windowHeight = window.innerHeight

   const elementWidth = target.clientWidth
   const elementHeight = target.clientHeight

   const centerLeft = (windowWidth / 2) - (elementWidth / 2)
   const centerTop = (windowHeight / 2) - (elementHeight / 2)

   target.style.left = `${centerLeft}px`;
   target.style.top = `${centerTop}px`;
}