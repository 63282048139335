import './experience.scss'
import data from './data'
import calendarIcon from 'static/icon/calendar.png'

export default function Experience() {
   return (
      <div className="experience-container">
         <div className="experiences">
            {data.map((experience, index) => (
               <div className="experience" key={index}>
                  <div className="company">
                     <img src={experience.companyLogo} alt={experience.company} title={experience.company} className="logo" />
                     <div className="name">{experience.company}</div>
                     <div className="position">{experience.position} - {experience.type}</div>

                     <div className="date">
                        <img src={calendarIcon} alt="Date" title='Date' className="icon" />
                        <div className="text">{experience.date.from} <div className="separator" /> {experience.date.to}</div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}