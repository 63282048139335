import './index.scss'
import {useEffect, useState} from 'react'
import Warning from './warning.png'

export default function ErrorWindowSize() {
   const [width, setWidth] = useState(0)

   const handleResize = () => {
      setWidth(window.innerWidth)
   }

   useEffect(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
         window.removeEventListener('resize', handleResize)
      }
   }, [])
   
   return (
      <div className="error-window-size" show={width <= 1000 ? '1' : "0"}>
         <div className="wrapper">
            <div className="container">
               <img src={Warning} alt="Warning" title='Warning' className="warning" />
               <div className="title">Whoops</div>
               <div className="desc">Website ini tidak mendukung ukuran layar anda.</div>
            </div>
         </div>
      </div>
   )
}