export default function NotFoundContent({onClose, id}) {
   return (
      <div className="not-found-content" style={{
         padding: '7px 15px',
         position: 'relative',
         width: 'calc(100% - 30px)',
         height: 'calc(100% - 14px)'
      }}>
         <div className="text">
         Application not found
         </div>

         <button style={{
            position: 'absolute',
            bottom: 15,
            right: 25,
         }} onClick={() => onClose(id)}>Close</button>
      </div>
   )
}