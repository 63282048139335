import basicTechnicalSupport from 'static/images/certs/basic_it_support_google_certificate.png'
import kidsUmm from 'static/images/certs/kids2022_umm.png'
import bdd from 'static/images/certs/baparekraf_event_dicoding.png'
import jsAlgorithmFreeCodecamp from 'static/images/certs/javascript_algorithm_freecodecamp.png'
import responsiveWebDesignFreeCodecamp from 'static/images/certs/responsive_web_design_freecodecamp.png'
import scientificComputingWithPython from 'static/images/certs/scientific_computing_freecodecamp.png'
import cSharpCourse from 'static/images/certs/csharp_course_sololearn.png'
import phpCourse from 'static/images/certs/php_course_sololearn.jpg'
import idcamp from 'static/images/certs/idcamp_software_architect.png'
import devCoacing from 'static/images/certs/developer_coaching.png'
import economicWar from 'static/images/certs/hadapi_tantangan_ekonomi_global.png'

export default [
   [
      {
         image: basicTechnicalSupport,
         title: 'Basic Technical Support',
         issued: {
            at: 'May 9, 2022',
            by: {
               instance: 'Google',
               name: 'Amanda Brophy - Global Director of Google Career Certificates'
            }
         }
      },
      {
         image: kidsUmm,
         title: 'Student Digital Innovation Competition (2022)',
         desc: 'Won 1st place in the national level software development competition organized by UMM (University of Muhammadiyah Malang).',
         issued: {
            at: 'May 9, 2022',
            by: {
               instance: 'Universitas Muhammadiyah Malang',
               name: 'Christian S.K.Aditya - Lecturer'
            }
         }
      }
   ],
   [
      {
         image: bdd,
         title: 'Baparekraf Developer Day 2022',
         issued: {
            at: 'Apr 2, 2022',
            by: {
               instance: 'Dicoding',
               name: 'Narenda Wicaksono - Chief Executive Officer'
            }
         }
      },
      {
         image: jsAlgorithmFreeCodecamp,
         title: 'Javascript Algorithm and Data Structures',
         issued: {
            at: 'Apr 7, 2022',
            by: {
               instance: 'freeCodeCamp',
               name: 'Quincy Larson - Executive Director'
            }
         }
      }
   ],
   [
      {
         image: responsiveWebDesignFreeCodecamp,
         title: 'Responsive Web Design',
         issued: {
            at: 'Dec 2, 2021',
            by: {
               instance: 'freeCodeCamp',
               name: 'Quincy Larson - Executive Director'
            }
         }
      },
      {
         image: scientificComputingWithPython,
         title: 'Scientific Computing with Python',
         issued: {
            at: 'Nov 5, 2021',
            by: {
               instance: 'freeCodeCamp',
               name: 'Quincy Larson - Executive Director'
            }
         }
      }
   ],
   [
      {
         image: cSharpCourse,
         title: 'C# Course',
         issued: {
            at: 'Nov 6, 2021',
            by: {
               instance: 'SoloLearn',
               name: 'Yeva Hyusyan - Chief Executive Officer'
            }
         }
      },
      {
         image: phpCourse,
         title: 'PHP Course',
         issued: {
            at: 'Nov 9, 2021',
            by: {
               instance: 'SoloLearn',
               name: 'Yeva Hyusyan - Chief Executive Officer'
            }
         }
      }
   ],
   [
      {
         image: idcamp,
         title: 'IDCamp x Dicoding: Software Architecture',
         issued: {
            at: 'Sep 24, 2022',
            by: {
               instance: 'Dicoding',
               name: 'Narenda Wicaksono - Chief Executive Officer'
            }
         }
      },
      {
         image: devCoacing,
         title: 'Dicodng Developer Coacing',
         issued: {
            at: 'Okt 21, 2022',
            by: {
               instance: 'Dicoding',
               name: 'Narenda Wicaksono - Chief Executive Officer'
            }
         }
      }
   ],
   [
      {
         image: economicWar,
         title: 'Hadapi Tantangan Ekonomi Global dengan Talenta Digital',
         issued: {
            at: 'Okt 29, 2022',
            by: {
               instance: 'Dicoding',
               name: 'Narenda Wicaksono - Chief Executive Officer'
            }
         }
      }
   ]
]