import style from './index.module.css'
import background from 'static/images/background.jpg'
import Background from './Background'
import Taskbar from '@component/Taskbar'
import Applications from '@component/Applications'
import {useEffect, useState} from 'react'

export default function Body() {
    const [openedMenuApplication, setOpenedMenuApplication] = useState([])

    const handleOpenApp = app => {
        var x = [...openedMenuApplication]
        const i = x.findIndex(x=> x.title === app.title)
        if (i<0) {
            x.push({
                id: app.title,
                ...app
            })
        } 
        setOpenedMenuApplication(x)
    }

    const handleCloseMenuApplication = id => {
        var x = [...openedMenuApplication]
        const i = x.findIndex(x => x.id === id)
        if (i>=0) {
            x.splice(i, 1)
        }
        setOpenedMenuApplication(x)
    }

    return (
        <div className={style.body}>
            <Background src={background} />
            <Applications onOpen={handleOpenApp} />
            <Taskbar apps={[]} menuApplications={openedMenuApplication} onCloseMenuApplication={handleCloseMenuApplication} />
        </div>
    )
}