import style from './index.module.css'
import Profile from './Profile'

export default function Start({open, onClose})  {
    return (
        <>
            <div className={style.wrapper} open={open} onClick={onClose} />
            <div className={style.start} open={open}>
                <div className={style.container}>
                    <Profile />
                </div>
            </div>
        </>
    )
}